
.artical-share{
    justify-content:center;
    font-size: 14px;
    padding: 2rem;
}

.artical-share .title{
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1.5rem 0;
}
.artical-share .date{
    color:#bbb;
    padding-left:2rem;
}
.artical-share .sub-title{
    padding: 1.7rem 0rem;
}