/* 页面header样式 */
.topfixed{position: fixed;width:calc(100% - 200px);z-index: 2;transition: width 0.2s;}
.ant-layout-sider-collapsed+.ant-layout .topfixed{width:calc(100% - 80px);transition: width 0.2s;}
.ant-layout-header{height: auto!important;}
.headers{background-color: #fff;}
.header-top{height: 60px;line-height: 60px;padding: 0 20px;text-align: right;}
.header-logo{line-height: 60px;text-align: left;font-size: 18px;background-color: #1890ff;}
.header-logo>img{height: 40px;vertical-align: middle;}
.header-logo span{margin-left: 5px;}
.header-logo a{margin-left: 40px;}
.header-drop{max-width: 300px;}
.ant-layout-header {height: 64px;padding: 0;line-height: 64px;background: #fff;}
.ft500{font-weight: 500!important;}
/* 页头面包屑 */
.breadcrumb{height: 40px;line-height: 40px;padding: 0 20px;border-top: 1px solid #f9c700;background: #fff}
.breadcrumb-title{text-align: center;font-size: 14px;}
.breadcrumb-title:after{position: absolute;content: '';left: 44%;top:39px;border-top: 9px solid #fff;border-left: 12px solid transparent;border-right: 12px solid transparent;}
/* 面包屑bell下拉样式 */
.breadcrumb-close{ float: right; pointer-events: none}
.header-drop,ul,li>a{white-space: pre-wrap}
.ant-dropdown-menu.header-drop{max-height: 500px;overflow: auto;}
/*表格*/
table td{ 
    max-width:80px; 
    word-wrap:break-word; 
    text-overflow:ellipsis; 
    white-space:nowrap; 
    overflow:hidden; 
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding:8px 0px;
}
.warpTd tbody tr td:nth-child(10){
    white-space:normal;
}
/* 侧边栏logo样式 */
/* .logo{line-height: 60px;text-align: left;font-size: 18px;background-color: #002140;} */
/* .logo>img{height: 40px;vertical-align: middle;}
.logo>span{margin-left: 5px;color:#ddd} */
/* 侧边栏trigger样式调整 */
.leftfixed{position: fixed;width:200px;height:100%;padding-top:90px;z-index: 4;}
.ant-layout-sider-collapsed ul.leftfixed{width:80px;}
.ant-layout-sider-trigger {z-index: 3;width: 40px!important;height: 40px;color: #aaa;line-height: 48px;text-align: center;background: #fff;cursor: pointer;-webkit-transition: all 0.2s;transition: all 0.2s;}
.logo {transition: width 0.2s;line-height: 90px;font-size: 18px;text-align: center;background-color: #002140;position: fixed;height: 90px;width:200px;z-index: 5;}
.logo>span {white-space: nowrap;color:#ddd}
.logo img {height: 35px;height: 40px;vertical-align: middle;}
.logo>.h1 {color: #fff;font-size: 18px;display: inline-block;margin: 0 0 0 10px}
.ant-layout-sider-collapsed .logo{width:80px;}
.topiconfixed{position: fixed;top:18px;left:212px;z-index: 3;transition: left 0.2s;}
.ant-layout-sider-collapsed .topiconfixed{left:92px;transition: left 0.2s;}
/* 内容区域样式 */
.container{background: #fff;margin:20px 20px 0px 20px;padding: 20px;margin-top: 120px;}
/* 首页样式 */
.height450{height: 450px;}
.plred{border-left: 5px red solid;}
.plblue{border-left: 5px #1890ff solid;}
.plorange{border-left: 5px orange solid;}
.plpruple{border-left: 5px #ff33ff solid;}
.button-fixed-write{position: fixed;left: 95%;top: 65%;}
.button-fixed-view{position: fixed;left: 95%;top: 75%;}
.btn-green:hover,.btn-green:focus{border-color: #52c41a;background: #52c41a;color:#fff}
.btn-green{border-color: #389e0d;background: #389e0d;color:#fff}
.home-pb30{padding-bottom: 30px!important;}
.max-height500-auto{max-height: 500px;overflow:auto;}
.green{color:#389e0d}
.home .ant-badge-dot, .ant-badge .ant-scroll-number-custom-component{right: -15px;top: -5px;}
.view-btn-badge{position: fixed;left: 97%;width: fit-content;top: 75%;}
.ant-menu-root>li:first-child>a{white-space: inherit;}
.legalTreeProjectManage .ant-tree-iconEle.ant-tree-icon__customize{width: 100%;margin-left:10px;float:right;}
.legalTreeProjectManagePop .ant-popover-inner-content{padding: 0px 16px;}
.legalTreeProjectManagePop .inputForm{position: relative;top: -10px;}
.legalTreeProjectManagePop .ant-popover-inner {-webkit-box-shadow:none;box-shadow:none;-webkit-box-shadow:none;box-shadow:none;}
.legalTreeProjectManagePop .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {left: 6px;border-top-color: transparent;border-right-color: transparent;border-bottom-color: transparent;border-left-color: transparent;}
.legalTreeProjectManagePop.ant-popover {z-index: 1000;}
/* 弹框 最大高度：500 */
.modal-max-height-500 .ant-modal-body{max-height: 500px;overflow: auto;}
/* form-item 去除下边距 */
.form-item-mb0 .ant-form-item {margin-bottom: 0px;}
.z1005{z-index: 10001}
/** load **/
.ajax-loading{display: none;}
.ajax-loading .loading{position:fixed;top:50%;left:50%;transform:translate(-50%,-50%);padding:0 40px;height:80px;line-height:80px;background:rgba(0,0,0,0.75);border-radius:6px;text-align:center;z-index:9999;font-size:16px;color:#fff;}
.ajax-loading .loading img{width: 32px;vertical-align: middle;}
.ajax-loading .loading span{margin-left:12px;}
.ajax-loading .overlay{position:fixed;left:0;right:0;top:0;bottom:0;height:100%;width:100%;z-index:9998;background:rgb(255,255,255);opacity:0.2;}
/*表格拉伸样式*/
.react-resizable {position: relative;background-clip: padding-box;}
.react-resizable-handle {
    /* 另一种拖拽样式
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0; 
    */
    position: absolute;width: 10px;height: 100%;bottom: 0;right: -5px;cursor: col-resize;z-index: 1;}
/*另一种拖拽样式*/
/*
.react-resizable-handle-sw {bottom: 0;left: 0;cursor: sw-resize;transform: rotate(90deg);
}
.react-resizable-handle-se {bottom: 0;right: 0;cursor: se-resize;}
.react-resizable-handle-nw {top: 0;left: 0;cursor: nw-resize;transform: rotate(180deg);}
.react-resizable-handle-ne {top: 0;right: 0;cursor: ne-resize;transform: rotate(270deg);}
.react-resizable-handle-w,
  .react-resizable-handle-e {top:50%;margin-top:-10px;cursor:ew-resize;}
.react-resizable-handle-w{left:0;transform:rotate(135deg);}
.react-resizable-handle-e{right:0;transform:rotate(315deg);}
.react-resizable-handle-n,
.react-resizable-handle-s{left:50%;margin-left:-10px;cursor:ns-resize;}
.react-resizable-handle-n{top:0;transform:rotate(225deg);}
.react-resizable-handle-s{bottom:0;transform:rotate(45deg);} 
*/
/********** 段 ***********/
/* 用户管理-企业用户-批量开通软件弹出 */
.SoftWareList .ant-form-item{margin-right: 0;}
.SoftWareList .ant-form-item .ant-form-item-label{width:120px;margin-right:10px;}
.SoftWareList .ant-form-item .ant-form-item-control-wrapper{width: 400px;}
.SoftWareList .ant-form-item.ant-form-item-with-help{margin-bottom: 0;}
.SoftWareList .ant-form-item .ant-input-number{width:320px;}
.SoftWareList .ant-form-item .ant-calendar-picker{width:320px;margin-right: 5px;}
/* 用户管理-企业用户-基本信息 */
.CardBorderNone .ant-card-head{border:none;background: #f0f2f5;min-height: 40px;}
.CardBorderNone .ant-card-head .ant-card-head-title{padding: 8px 0;}
.CardBorderNone .ant-card-head .ant-card-extra{padding: 4px 0;}
.CardBorderNone .ant-card-body{padding:0;}
.CardBorderNone .ant-form{text-align: center;}
.CardBorderNone .ant-form-item .ant-form-item-label{width: 180px;margin-right: 10px;}
.CardBorderNone .ant-form-item .ant-form-item-control-wrapper{width: 330px;text-align: left;}
.unitBasicEdit>.ant-form-item:last-child{width: 100%;margin: 0;background: #f0f2f5;margin-top: 24px;}
.unitBasicEdit>.ant-form-item:last-child>.ant-form-item-control-wrapper{width:auto;}
.unitBasicEdit>.ant-form-item:last-child>.ant-form-item-control-wrapper>.ant-form-item-control{line-height: 44px;}
.unitBasicView{padding: 30px 0;}
.unitBasicView .ant-form-item div.unitBasicData{min-height:30px;line-height: 30px;padding: 0 8px;margin: 5px 0;border-bottom: #ddd solid 1px;color: #999;}
.unitBasicEdit{padding-top: 30px;}
.unitBasicEdit .address{float: left;margin-right: 5px;}
.unitBasicEdit .addressed{float: left;margin-right: 0;}
.unitBasicEdit .ant-form-item .ant-calendar-picker{width: 100%;}
.unitBasicEdit .ant-form-item .ant-input-number{width: 270px;}
.unitBasicEdit .ant-form-item.kongForm,
.unitBasicView .ant-form-item.kongForm{width: 520px;}
.uploadImg{width: 100px!important;height: 100px;float: left;padding: 1px 1px 1px 1px;margin-right: 10px;border-radius: 5px;margin-top:2px;}
.uploadImgBtn .avatar-uploader.ant-upload-picture-card-wrapper{width:100px!important;}
/*用户管理-企业用户-职业健康*/
.HealthBasicPicture{padding: 24px; width: 100%; height: auto; overflow: hidden;}
.HealthBasicPicture img{float: left;margin-right: 40px;}
.HealthBasicPicture ul{width:380px;height: auto; float: left;padding: 0 30px;border-left: #ddd solid 1px;}
.HealthBasicPicture ul li{width: 100%; height: auto;overflow: hidden;}
.HealthBasicPicture .title{width: 115px;height: 28px;float: left;line-height: 28px;text-align: right;font-size: 14px;color: #666;}
.HealthBasicPicture .neire{width: 200px;height: 28px;float: left;line-height: 28px;font-size: 14px;color: #333;}
.HealthBasicPicture ul li a{width:100%;height: 24px;display: block;line-height: 24px;text-align: right;}
.SoftWareWidth .ant-form-item.widtHalf .ant-form-item-control-wrapper{width:170px;}
.SoftWareWidth .ant-form-item .ant-form-item-control-wrapper{width:470px;}
.ant-card .ant-table-pagination.ant-pagination{padding-right: 16px;}
/*用户管理-体检查询管理-体检单-编辑*/
.SoftWareList .has-feedback{width:100%;}
.warning{color: #faad14;}

/*健康顾问订单-图文订单-弹出*/
.PointLine{width: 100%; height: 4px; background: #f2f2f2;margin: 15px 0;}
.askQuestion .piCenTitle{font-size: 16px;font-weight: bold;margin-bottom: 10px;}
.askQuestion .piCenNeirp{margin-bottom: 10px;}
.askQuestion .piCenPicture{width: 100%;height: auto;}
.askQuestion .piCenPicture img{margin: 5px;height: 100px;}
.HealthCertificate .HealthTitle{width: 100%;height: 30px;}
.HealthTitle .HealthTitLeft,
.HealthTitle .HealthTitRight{width: 100px;height: 30px;line-height: 30px;text-align: right;float: left;font-weight: bold;margin-right: 20px;}
.HealthTitle .HealthTitRight{text-align: left;width: 200px;}
.HealthCertificate .HealthPerop li{width: 100%;height: auto;overflow: hidden;}
.HealthCertificate .HealthPerop .HealthTitl,
.HealthCertificate .HealthPerop .HealthNeir{width: 100px; height: 30px; line-height: 30px;text-align: right;float: left;margin-right: 20px;}
.HealthCertificate .HealthPerop .HealthNeir{text-align: left;width: 510px;height: auto;}




/*行政区划*/
 .manage .ant-table-thead > tr > th{
     text-align: center;
 }
 .manage tbody.ant-table-tbody td{
     padding-left: 8px !important;
 }
/*体检*/
 .mb11{margin-bottom: 10px;}
 .mb11 form.ant-form.ant-form-inline{
     text-align: left;
     padding-left: 20px;
 }
 .mb11 .ant-form-inline .ant-form-item{
     margin-right: 0px;
 }
.mb11 div.ant-table-wrapper{
    padding-left: 20px;
    padding-right: 20px;
}
.mb11 div.ant-card.CardBorderNone.ant-card-bordered{
    margin-bottom: 20px !important;
}
 /* 个人用户管理 */
 .headUrlPic .ant-upload.ant-upload-select-picture-card{width:100px;height: 100px;}
 .IdCardpic .ant-upload.ant-upload-select-picture-card{width: 300px;}  
 .userBasiPForm .m0t10{margin:0;margin-top:10px;}
 .userBasiPForm .lineh40{height:40px;line-height:40px;}
 .userBasiPForm .btnright{font-size:12px;line-height:100%;height:100%;padding:4px;margin:0 10px;}
 .userBasiPForm .buttonPic{width:300px;height:170px;text-align: center;line-height: 170px;border:1px solid #ddd;padding:5px;margin:auto}
 .userBasiJY .title{width:100%;padding:5px;background: #d9edf7;color:#1890ff;margin:20px 0;}
 .userBasiJY .showdetail{font-size: 12px;}
 .userBasiJY .showdetail  span{width:30%;display:inline-block;}
 .userBasiJY .showdetail  span+span{width:70%;display:inline-block;}
 .userBasiJY .showdetail hr{width:100%;margin:10px auto 20px;border:2px solid #c2e0fd;}
 .closeOrderFrom .ant-col{width:100%}
 .userBasiJY .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content{margin-left:0}
 .userBasiJY .btnright{font-size:12px;line-height:100%;height:100%;padding:4px;margin:0 10px;}
 .userBasiJY .blue-connecting{display: inline-block;width:200px;height:2px;background: #1890ff;position: absolute;top:15px;}
 .docEditor .practicePic{width:160px}
 .docEditor .ant-upload.ant-upload-select-picture-card{width:170px}
 .docEditor .cart-box{width:100%;min-width:1100px;box-shadow: 0 0 20px 0 rgba(86, 91, 94, 0.1);margin-top:20px;padding:20px;}
.GoodAtAdd .leftTitle{background:#e8e8e8;display: flex;text-align:center;align-items: center;}
.GoodAtAdd .rightBody{display: flex;text-align:center;align-items: center;}
.userBasiJY .CardBorderNone .ant-form{text-align: left;}
.userBasiJY .CardBorderNone{margin-top:20px;}
.userBasiPForm .CardNone .ant-card-head{border:none;background: #f0f2f5;min-height: 40px;}
.userBasiPForm .CardNone .ant-card-head .ant-card-head-title{padding: 8px 0;}
.userBasiPForm .CardNone .ant-card-head .ant-card-extra{padding: 4px 0;}

.newtable div.ant-col.ant-col-12.ant-form-item-label{
    display: inline-block;
    overflow: hidden;
    line-height: 39.9999px;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
}
.newtable .ant-form-item{
    margin-bottom: 0px !important;
}
.newtable1 .ant-form-item{
    margin-bottom: 0px !important;
}
.newtable1 .none{
    display: none;
}

/*left menu*/
.menu-scorll{
    overflow-y: auto;
    scrollbar-color: #001529 #001529;  /*firefox*/
}
/* left menu for chorme */
.menu-scorll::-webkit-scrollbar {
    width:0px;
}
.menu-scorll::-webkit-scrollbar-track-piece {
    background:#001529
}
.menu-scorll::-webkit-scrollbar-thumb{
    background:#ccc;
    border-radius:4px;
}
/* left menu for ie */
.menu-scorll{
    scrollbar-highlight-color:#001529;
    scrollbar-face-color:#ccc;
    scrollbar-arrow-color:#001529;	
    scrollbar-shadow-color:#001529;	
    scrollbar-dark-shadow-color:#001529;
    scrollbar-base-color:#001529;	
    scrollbar-track-color:#001529;
}
/* 每周答题弹框样式 */
.detail .ant-modal-content{
    width: 1000px;
}
.detail .show{
    color: red;
}
.move .show{
    color: red;
}
.ProblemData .show{
    color: red;
}
div.ant-modal.detail{
    width: 900px !important;
}
.detail table td{
    white-space: normal;
    word-break: break-all;
}
/* 拖拽表格 */
.move tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }
  
.move tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
.move table td{
    white-space: normal;
    word-break: break-all;
}

/* 文章 */

.article-addNote td{
    border:1px solid #e8e8e8;
    padding:5px;
    height: 42px;
}
.article-addNote tr>td:first-child{
    width: 20%;
}
.article-addNote tr>td:nth-child(3){
    width: 20%;
}
.article-lookNote td{
    border:1px solid #e8e8e8;
    padding:10px;
}
.article-lookNote tr>td:nth-child(3){
    width: 20%;
}
.article-lookNote .value-td{
    width: 30%;
}
.lookNote-detail table tr>td:first-child{
    width: 20%;
}
.Html-style img{
    max-width: 100%;
}
/* 总题库样式 */
.ProblemData .ant-modal-content{
    width: 1000px;
}
div.ant-modal.ProblemData{
    width: 900px !important;
}
.ProblemData table td{
    white-space: normal;
    word-break: break-all;
}
.ProblemData .ant-table-thead th{
    text-align: center;
}
.ProblemData .ant-modal-footer{
    text-align: center;
}
.ProblemData .ant-modal-content{
    bottom:50px;
}
.weekTitle{
    width:100%;
    height: 40px;
    margin-bottom: 18px;
}
.weekTitle1{
    border-left: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
    padding: 5px;
    height: 45px;
    line-height: 35px;
    width:30%;
    float: left;
}
.weekTitle2{
    border: 1px solid #e8e8e8;
    text-align: left;
    padding: 5px;
    height: 45px;
    line-height: 35px;
    width:70%;
    float: left;
}
.seeTable{
    width: 100%;
    margin-bottom: 15px;
}
.seeTable td{
    border: 1px solid #e8e8e8;
    text-align: center;
    padding: 10px;
}
.seeTable tr td:nth-child(2n+1){
    width: 20% !important;
}
.seeTable tr td:nth-child(2n){
    width: 30% !important;
}
.unshow{
    display: none;
}
.inputSpecial{
    width: 88%;
}
.Article-FromItem-Image .ant-upload-picture-card-wrapper{
    margin-bottom: -18px;
}
.family-inherit-text *{
    font-family: inherit;
}
.family-inherit-text img{
    width: 100%;
}